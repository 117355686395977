import _es6Object from "../../modules/es6.object.create";
import _core from "../../modules/_core";
var exports = {};
_es6Object;
var $Object = _core.Object;

exports = function create(P, D) {
  return $Object.create(P, D);
};

export default exports;